import Link from "next/link";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { buyModalHide } from "../../redux/counterSlice";
import { Confirm_checkout } from "../metamask/Metamask";
import LoadingButton from "../loading-button";
import {
  addItemToCart,
  removeItemFromCart,
  selectCart,
} from "../../redux/cartSlice";
import { useRouter } from "next/router";
import NumberInput from "../number-input";
import { selectTransformedPokemons } from "../../redux/pokemonSlice";
import EmptyState from "../empty-state";
import Image from "next/image";

const BuyModal = () => {
  const dispatch = useDispatch();
  const selectedPokemons = useSelector(selectTransformedPokemons); // Select transformed pokemons

  const router = useRouter();
  const { buyModal } = useSelector((state) => state.counter);

  const cart = useSelector(selectCart);

  const handleCheckout = async () => {
    dispatch(buyModalHide());
    router.push("/checkout");
  };

  const handleBuyNow = async () => {
    dispatch(buyModalHide());
    router.push("/");
  };

  const handleSubtract = (id, quantity, isFullPayment) => {
    let orderAmount = quantity;
    if (orderAmount > 1) {
      orderAmount = orderAmount - 1;
    }
    const currentPokemon = selectedPokemons.find(
      (pokemon) => pokemon.id === id
    );

    dispatch(
      addItemToCart({ ...currentPokemon, isFullPayment, quantity: orderAmount })
    );
  };

  const handleAdd = (id, quantity, isFullPayment) => {
    let orderAmount = quantity;
    orderAmount = orderAmount + 1;

    const currentPokemon = selectedPokemons.find(
      (pokemon) => pokemon.id === id
    );
    dispatch(
      addItemToCart({ ...currentPokemon, isFullPayment, quantity: orderAmount })
    );
  };

  return (
    <div>
      {/* <!-- Buy Now Modal --> */}
      <div className={buyModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl min-w-[40%]">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                Your Cart
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(buyModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center gap-2">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white grow">
                  Item
                </span>
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white flex-none w-19">
                  Subtotal
                </span>
              </div>
              {cart.items.length === 0 ? (
                <EmptyState message="Cart is empty" />
              ) : (
                <span>
                  {cart.items.map(
                    (
                      {
                        image,
                        id,
                        title,
                        isFullPayment,
                        digitPrice,
                        preOrderPrice,
                        quantity,
                      },
                      key
                    ) => {
                      return (
                        <div key={id}>
                          <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4 gap-2 flex-wrap">
                            <span className="grow flex gap-2 max-w-[calc(100%-80px)]">
                              <figure className="self-start">
                                <Image
                                  src={image}
                                  alt="avatar 2"
                                  width={60}
                                  height={60}
                                  className="rounded-2lg h-[60px]"
                                  loading="lazy"
                                />
                              </figure>

                              <div>
                                <a
                                  href={`/product/${id}`}
                                  className="text-accent text-m"
                                >
                                  {title}
                                </a>
                                {/* <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                        Lazyone Panda
                      </h3> */}
                                <div className="flex flex-wrap items-center">
                                  <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                                    {isFullPayment
                                      ? "Full Payment"
                                      : "Partial Payment"}
                                  </span>
                                </div>
                              </div>
                            </span>

                            <div className="flex-none w-19">
                              <span className="mb-1 flex items-center whitespace-nowrap">
                                <span className="dark:text-jacarta-100 text-sm font-medium tracking-tight">
                                  R{" "}
                                  {isFullPayment
                                    ? (quantity * digitPrice).toFixed(2)
                                    : (quantity * preOrderPrice).toFixed(
                                        2
                                      )}{" "}
                                </span>
                              </span>
                              {/* <div className="dark:text-jacarta-300 text-right text-sm">
                        $130.82
                      </div> */}
                            </div>
                          </div>

                          <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center space-x-4 whitespace-nowrap">
                              <NumberInput
                                subcract={() =>
                                  handleSubtract(id, quantity, isFullPayment)
                                }
                                add={() =>
                                  handleAdd(id, quantity, isFullPayment)
                                }
                                showLabel={false}
                                amount={quantity}
                              />
                            </div>
                            <div className="mt-4 flex items-center justify-between">
                              <button
                                className="text-red font-display text-sm font-semibold"
                                onClick={() => dispatch(removeItemFromCart(id))}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}

                  {/* <!-- Total --> */}
                  <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
                    <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                      Total
                    </span>
                    <div className="ml-auto">
                      <span className="flex items-center whitespace-nowrap">
                        {/* <span data-tippy-content="ETH">
                      <svg className="h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg>
                    </span> */}
                        <span className="text-green font-medium tracking-tight">
                          R {cart.total.toFixed(2)}
                        </span>
                      </span>
                      {/* <div className="dark:text-jacarta-300 text-right">
                    $130.82
                  </div> */}
                    </div>
                  </div>
                </span>
              )}
              {/* <!-- Terms --> */}
              {cart.items.length > 0 && (
                <div className="mt-4 flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="buyNowTerms"
                    className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                  />
                  <label
                    htmlFor="buyNowTerms"
                    className="dark:text-jacarta-200 text-sm"
                  >
                    By checking this box, I agree to {"Ixcitement's"}{" "}
                    <Link href="/tarms" className="text-accent">
                      Terms of Service
                    </Link>
                  </label>
                </div>
              )}
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {cart.items.length > 0 ? (
                  <LoadingButton
                    onClick={handleCheckout}
                    disabled={cart.items.length === 0}
                  >
                    Check Out
                  </LoadingButton>
                ) : (
                  <LoadingButton onClick={handleBuyNow}>Buy Now</LoadingButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyModal;
