import React from "react";

const NumberInput = ({
  amount = 1,
  label = "Quantity",
  showLabel = true,
  subcract,
  add,
}) => {
  return (
    <div>
      {showLabel && (
        <label
          for="quantity-input"
          className="block mb-2 text-sm font-medium font-semibold dark:text-white"
        >
          {label}
        </label>
      )}
      <div className="relative flex items-center max-w-[8rem]">
        <button
          onClick={subcract}
          type="button"
          id="decrement-button"
          data-input-counter-decrement="quantity-input"
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
        >
          <svg
            className="w-3 h-3 text-gray-900 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 2"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h16"
            />
          </svg>
        </button>
        <span className="bg-gray-50 border-x-0 border-gray-300 h-11 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500  py-2.dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 text-jacarta-700 dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-white w-10 text-center flex justify-center items-center">
          {amount}
        </span>
        <button
          onClick={add}
          type="button"
          id="increment-button"
          data-input-counter-increment="quantity-input"
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
        >
          <svg
            className="w-3 h-3 text-gray-900 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 1v16M1 9h16"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NumberInput;
