// src/store/slices/pokemonSlice.js
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

// Async thunk for fetching Pokémon data
export const fetchPokemons = createAsyncThunk(
  "pokemon/fetchPokemons",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("/api/contentful");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const pokemonSlice = createSlice({
  name: "pokemon",
  initialState: {
    pokemons: [],
    loading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPokemons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPokemons.fulfilled, (state, action) => {
        state.loading = false;
        state.pokemons = action.payload;
      })
      .addCase(fetchPokemons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Selector for raw Pokémon state
export const selectPokemons = (state) => state.pokemon.pokemons;

// Derived selector to transform Pokémon data
export const selectTransformedPokemons = createSelector(
  [selectPokemons],
  (pokemons) =>
    pokemons.map(
      ({
        id,
        imageUrl,
        name,
        price,
        description,
        preOrderPrice,
        category,
      }) => ({
        id,
        image: imageUrl[0],
        imageList: imageUrl,
        category,
        title: name,
        nfsw: true,
        lazyMinted: false,
        verified: true,
        text: description,
        addDate: 1,
        sortPrice: price,
        digitPrice: price,
        preOrderPrice,
        price: `R ${price.toFixed(2)}`,
        bidLimit: 8,
        bidCount: 2,
        likes: Math.floor(Math.random() * (40 - 10 + 1)) + 10,
        creator: {
          name: "Sussygirl",
          image: "/images/avatars/creator_1.png",
        },
        owner: {
          name: "Sussygirl",
          image: "/images/avatars/owner_1.png",
        },
        creatorImage: "/images/avatars/avatar_7.jpg",
        ownerImage: "/images/avatars/avatar_1.jpg",
        creatorname: "@creative_world",
        ownerName: "@051_Hart",
        auction_timer: "636234213",
        bid_number: 1.55,
        eth_number: 1.3,
        react_number: Math.floor(Math.random() * (40 - 10 + 1)) + 10,
      })
    )
);

export default pokemonSlice.reducer;
