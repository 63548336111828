// localStorageHelper.js

export const setItem = (key, value) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getItem = (key) => {
    if (typeof window !== "undefined") {
        const value = localStorage.getItem(key);
        try {
            return value ? JSON.parse(value) : null;
        } catch (error) {
            // If the value can't be parsed, log the error and return null
            console.error("Error parsing JSON from localStorage", error);
            return null;
        }
    }
    return null;
};

export const removeItem = (key) => {
    if (typeof window !== "undefined") {
        localStorage.removeItem(key);
    }
};
